import React from 'react'
import { Helmet } from 'react-helmet'

const BlogPageSideSection = () => {
  return (
    <div>
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />
        <meta name={`googlebot`} content={`noindex, nofollow`} />
      </Helmet>
      BlogPageSideSection
    </div>
  )
}

export default BlogPageSideSection
